// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
	@extend .form-control;
	height: 38px;
}
.search-form .search-submit {
	@extend .btn;
	@extend .btn-default;
	color: #fff;
	font-size: 16px !important;
	line-height: 16px !important;

	&:hover {
		color: #fff;
	}
}

a.read-more  {
	@extend .btn;
	@extend .btn-primary;

	display: block;
	max-width: 180px;
	font-size: 16px;
	margin: 15px 0 0;
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
	@extend .btn;
	@extend .btn-primary;
}

input[type="email"],
input[type="number"],
input[type="search"],
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
textarea,
select {
	height: 38px;
	padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
	background-color: #fff;
	color: $text_input;
	border: 1px solid $border_input;
	border-radius: 0 !important;
	padding: 3px;
	box-shadow: none;
	box-sizing: border-box;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none; 

	&:focus {
		color: $text_input_focus;
		border: 1px solid $brand-secondary;
		outline: 0; 
	}
}

.gform_wrapper {
	textarea,
	select,
	fieldset,
	.top_label input.large {
		padding-left: 6px;
		margin-bottom: 1.5rem; 
}
	textarea.large {
		padding-left: 10px;
		height: 140px;

	}
}


/* Styles specific to Gravity Forms */
.gform_wrapper {
	ul {
		list-style: none;
		padding: 0;
		margin: 0;	

		li.gfield {
			margin-bottom: 0;
		}
	}

	input[type=text],
	input[type=email],
	input[type=tel],
	textarea {
		width: 100%;
		border-radius: 0;
	}

	.g-recaptcha {
		margin-bottom: 30px;
	}
}

.gform_wrapper .gform_footer{
	margin: 0;
}

.gform_wrapper .top_label .gfield_label,
.gform_wrapper textarea.large  {
	margin: 0;
}

// 404 page search form
.search-form {
	label {
	    display: inline-block;
	}

	input[type="submit"] {
		height: 38px;
		line-height: 36px;
	}
}

/* Keep CAPTCHA from breaking mobile layout */
@media screen and (max-width: 479px) {
	.g-recaptcha > div > div {
		border-right: 1px solid #d3d3d3;
		width: 290px !important;
		border-radius: 4px; 
	} 
}