.site-header {
  border-bottom: 1px solid #ddd;
}

.menu-container {
  background-color: $brand-white;
  border: none;
  margin: 0;
  padding: 5px 0;

  @include laptop {
    padding: 14px 0;
  }

  .navbar-collapse {
    margin: 0;
    .navbar-nav {
      float: right;
      // &:first-of-type {
      //   margin-bottom: 5px;
      // }
      li {
        a {
          padding: 8px 10px;
          font-size: 18px;
          font-weight: 300;

          @include desktop {

            padding: 10px 15px;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.logo-container {
  padding-right: 0;
}

.navbar-brand {
  text-align: center;
  height: auto;
  background: url(../images/logo.png) no-repeat center;
  background-size: contain;
  padding: 10px 0;
  margin: 10px 0;
  max-width: 220px;

  @include mobile {
    max-width: 100%;
    padding: 20px 0 10px;
    // margin: 5px 0 5px;
  }

  @include desktop {
    padding: 25px 0 0;
    margin: 0 0 5px;
  }

  span {
    display: block;
    font-weight: 300;
    color: $brand-secondary;
  }

  .logo-heading {
    font-size: 20px;
    text-transform: uppercase;
    // padding: 20px 0 5px;

    @include mobile {
      font-size: 28px;
      line-height: 30px;
    }

    @include desktop {
      font-size: 34px;
      line-height: 34px;
    }
  }
  .logo-text {
    font-size: 16px;

    @include desktop {
      font-size: 20px;
      padding: 5px 0 20px;
    }
  }
}


.page-header {
  margin: ($line-height-computed * 2) 0 ($line-height-computed / 2);
  border-bottom: none !important;
}