.widget:first-of-type h3 {
	@include laptop {
		margin-top: 0;
	}	
}

.site-main-area h3,
.contact-sidebar h4 {
	margin-top: 30px;
}

article {
	.entry-summary p {
		margin-bottom: 30px;
	}

	& + article {
		border-top: 1px solid #eee;
		padding-top: 20px;
		margin-top: 60px;
	}

	h2 {
		margin-top: 30px;
	}

	&:first-of-type h2 {
		margin-top: 0;
	}
}
