// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #314A5B;
$brand-secondary:       #823600;
$brand-button:          #1b82cc;
$brand-white:           #ffffff;
$text-primary:          #4e4e4e;
$trans-white: 			rgba(255,255,255,0.5);
$nav-border: 			#efefef;
$text_input:			#666;
$border_input:			#ccc;
$text_input_focus:		#111;
$form_border:			#d1d1d1;


// Common breakpoints
$width_mobile: 480px;
$width_phablet: 600px;
$width_tablet: 768px;
$width_laptop: 992px;
$width_desktop: 1200px;
$width_hdtv: 1600px;

// Media queries - @include mobile { selector: style; }
@mixin mobile 	{ @media (min-width: #{$width_mobile})	{ @content; } }
@mixin phablet 	{ @media (min-width: #{$width_phablet})	{ @content; } }
@mixin tablet 	{ @media (min-width: #{$width_tablet})	{ @content; } }
@mixin laptop 	{ @media (min-width: #{$width_laptop})	{ @content; } }
@mixin desktop 	{ @media (min-width: #{$width_desktop})	{ @content; } }
@mixin hdtv 	{ @media (min-width: #{$width_hdtv})	{ @content; } }

// Aspect ratio mixin - @include aspectRatio(16, 9);
@mixin aspectRatio($ratio-or-width, $height: null) {
	$padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
	&:before { content:''; float: left; padding-bottom: $padding; }
	&:after  { content:''; display: table; clear: both; } 
}

// Top and bottom inset box shadow - @include innerShadow;
@mixin innerShadow() {
    box-shadow: inset 0 6px 6px -6px rgba(0,0,0,0.5), inset 0 -6px 6px -6px rgba(0,0,0,0.5);
}