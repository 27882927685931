footer {
  text-align: center;
  // padding: 20px 0 50px;
  .footer-logo {
    display: inline-block;
    margin: 26px 0 10px;
  }
  .address {
    p, a {
      color: $brand-secondary;
      font-size: 20px;
      margin-bottom: 12px;
    }
    .dot:after {
      content: "\2022";
    }
  }
  .navbar {
    height: auto;
    min-height: 40px;
    margin: 0;
  }
  .navbar-nav {
    float: none;
    text-align: center;
    li {
      float: none;
      display: inline-block;
      margin-right: -4px;
      a {
        padding: 0 12px;
        font-size: 22px;
        font-weight: 300;
      }
      &:not(:last-of-type) {
        border-right: 1px solid black;
      }
    }
  }
  .footer-social {
    margin-bottom: 40px;

    img {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      &:hover, &:focus {
        opacity: 0.65;
      }
    }
  }
}

.footer-content {
    background: linear-gradient( rgba(255,255,255,0.9), rgba(255,255,255,0.9)), url('../images/courthouse.jpg');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    // @include innerShadow;
}

.disclaimer {
  background-color: #4e4e4e;
  color: #fff;

  .disclaimer-copy {
    font-size: 80%;
    font-style: italic;
    margin: 40px 0;

    h3 {
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      font-style: normal;
    }
  }
}

.copyright {
    background-color: #2E2E2E;
    color: #fff;
    font-size: 12px;
    padding: 15px 0;
    text-align: center;

    @include laptop {
        text-align: left;

        .footer-right span {
            float: right;
        }
    }
    
    a {
        color: #fff;
        font-size: 12px;

        &:hover {
            text-decoration: underline;
        }
    }
}