a.scrollup {
    position: fixed;
    z-index: 9000;
    z-index: 1000;
    bottom: 20px;
    right: 20px;
    display: none;
    border-radius: 50%;
    background-color: $brand-button;
    border: 1px solid $trans-white;
    padding: 0;
    height: 40px;
    width: 40px;

    &:hover {
        color: #fff;
        background-color: lighten( $brand-button, 5% );
        text-decoration: none;
    }

    &::before {
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: '';
        display: inline-block;
        height: 12px;
        width: 12px;
        top: 8px;
        left: 13px;
        position: relative;
        transform: rotate(-45deg);
        vertical-align: middle;
        color: #fff;
    }
}

