html, body {
  font-family: "Gill Sans", sans-serif;
  font-size: 18px;
}

p {
  color: $text-primary;
  font-weight: 300;
  margin-bottom: 20px;
}

.nav.navbar-nav {
  li a {
    &:hover, &:focus {
      background-color: initial;
      text-decoration: underline;
      color: $brand-secondary;
    }
  }
}

.site-main-area,
.contact-sidebar,
.site-sidebar {
  padding-bottom: 30px;

  @include laptop {
    padding-bottom: 50px;
  }
}

// .site-sidebar a.cta-button {
//   border: 2px solid #000;
//   color: #000;
//   white-space: normal;
//   padding: 1;
// }