$footer_fix_tablet_height: 611px;
$footer_fix_laptop_height: 574px;
$footer_fix_desktop_height: 583px;

html,
body {
	margin:0;
	padding:0;
	height:100%;
}

#page {
	min-height:100%;
	position:relative;
}

#content {
	@include tablet {
		padding-bottom: $footer_fix_tablet_height; 
	}

	@include laptop {
		padding-bottom: $footer_fix_laptop_height; 
	}

	@include desktop {
		padding-bottom: $footer_fix_desktop_height; 
	}
}

#footer {
	border-top: 1px solid #efefef;
	
	@include tablet {
		height: $footer_fix_tablet_height; 
		position:absolute;
		width:100%;
		bottom:0;
		left:0;
	}

	@include laptop {
		height: $footer_fix_laptop_height; 
	}

	@include desktop {
		height: $footer_fix_desktop_height; 
	}
}
