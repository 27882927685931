/*--------------------------------------------------------------
## Side navigation
--------------------------------------------------------------*/
$sideNavWidth: 240px;
$sideNavDirection: (right);

.sideNav {
    width: $sideNavWidth;
    #{$sideNavDirection}: -$sideNavWidth;
    position: fixed;
    z-index: 1000;
    height: 100%;
    top: 0;
    background: #fff;
    border-left: 1px solid #ddd;
    transition: all 0.3s ease;
    overflow: auto;

    h3 {
        // color: #fff;
        font-size: 24px;
        padding: 20px;
        margin: 0;
        font-weight: 300;
        background: #f4f4f4;
        border-bottom: 1px solid #ddd;
    }

    ul {
        margin: 0;
        padding: 0;
        width: 100%;
        position: relative;
        text-align: left;
        border: none;
        top: 0;

        ul {
            min-width: 100%;
            background: #444;

            li a {
                padding: 10px 10px 10px 40px;
            }
        }

        li {
            display: block !important;
            clear: both;
            width: 100%;

            a {
                background-color: transparent;
                // color: #fff;
                display: block;
                font-size: 16px;
                border-bottom: 1px solid $nav-border;
                padding: 10px 20px;
            }
        }

        &:before {
            border: none;
        }
    }

    &.sideNav-open {
        #{$sideNavDirection}: 0px;
    }

    .nav > li > a + a {
        position: absolute;
        right: 0;
        top: 0;
        transition: 0.3s;

        i {
            transition: 0.3s;
        }
    }

    .nav > li.open > a + a > i {
        transform: rotate(180deg);
    }
}

/* classes applied to the body */
.sideNavBody {
    overflow-x: hidden;
    position: relative;
    #{$sideNavDirection}: 0;
    transition: all 0.3s ease;
}

.sideNavBody-push {
    #{$sideNavDirection}: $sideNavWidth;
}




.dropdown-menu > li > a:hover, 
.dropdown-menu > li > a:focus,
.nav > li > a:hover, 
.nav > li > a:focus {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

.nav .open > a, 
.nav .open > a:hover, 
.nav .open > a:focus {
    background-color: transparent;
    border-color: #111;
}
