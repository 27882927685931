#map_canvas {
	width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    height: 300px;

    @include tablet {
    	height: 380px;
    }
}


/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

.video-container {
	@include aspectRatio(16, 9);
	position: relative;
	margin-bottom: 15px;

	iframe,
	object,
	embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	    border: none;
	}
}

