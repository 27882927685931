.btn.btn-primary {
  background-color: $brand-button;
  border-radius: 0;
  border: none;
  font-weight: 300;
  font-size: 26px;
  transition: background-color 0.75s;
  &.btn-block {
    padding: 8px 0;
  }
  &:hover, &:focus {
    background-color: darken($brand-button, 15%);
  }
}

