.hero {
  background: url(../images/hero.jpg);
  background-position: top left;
  background-repeat: no-repeat;
  padding: 30px 0 30px;
  text-align: left;

  @include laptop {
    padding: 240px 0 200px;
    background-position: bottom center;
    background-size: cover;
  }

  @include desktop {
    padding: 240px 0 200px;
  }

  h1 {
    text-transform: uppercase;
    color: $brand-white;
    font-size: 28px;
    font-weight: 400;
    margin: 0;

    @include laptop {
      font-size: 36px;
    }
  }

  p {
    // font-size: 24px;
    color: $brand-white;
    // line-height: 30px;
    margin-bottom: 30px;
    font-weight: 300;

    // @include laptop {
    //   font-size: 30px;
    // }
  }

  .btn {
    display: inline-block;
    max-width: 360px;
  }

  &.interior-hero {
    background: url(../images/hero-inner.jpg);
    text-align: center;

    @include laptop {
      padding: 60px 0;
      background-position: center;
      background-size: cover;
    }

    @include desktop {
      padding: 80px 0;
    }
  }
}

section#strapline {
  .strapline-text {
    background-color: $brand-primary;
    text-align: center;
    padding: 30px 0 20px;
    // margin-top: 0;
    p {
      color: #eee;
      padding: 0 40px;
      font-size: 22px;
      line-height: 1.3;
      font-weight: 300;
    }
  }
}

.awards {
    display: flex;
    flex: 1 0 100%;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;

    .award {
        display: flex;
        flex: 1 0 50%;
        max-width: 50%;
        justify-content: center;
        margin-top: 15px;

        @include phablet {
            flex: 1 0 33.333333%;
            max-width: 33.333333%;
        }
    }
}

section#practice {
  background-color: $brand-white;
  padding: 40px 0;
  h2 {
    font-weight: 300;
    margin-bottom: 5px;
  }
  // p {
  //   font-size: 22px;
  // }
  .family-law {
    p:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }
  .practice-area {
    margin-bottom: 58px;
    p {
      margin-bottom: 10px;
    }
  }
}


a.practice-btn {
  color: $brand-secondary;
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 40px;
  text-decoration: underline;
  &:hover, &:focus {
    font-weight: 400;
  }
  &:after {
    content: "\2002" "\00bb";
  }
}

section#contact-us {
  background-color: $brand-primary;

  .feature {
    position: relative;
    color: #fff;

    @include laptop {
      padding: 20px 0 40px;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
        right: 45%;
        background: url(../images/feature-scales.jpg) no-repeat right;
        background-size: cover;
      }
    }

    .feature-content {
      background-color: $brand-primary;
      position: relative;
      padding: 20px 15px 30px;

      @include laptop {
        padding: 0 15px 0 25px;
      }
    }
    h2 {
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 0;
    }
    p {
      color: #fff;
      // font-size: 24px;
      margin: 20px 0 25px;
    }

  }
}
